import Vue from 'vue'
import Additions from './additions'
import Main from './Main.vue'

Vue.config.productionTip = false

let config = require("./config.js");
if (process.env.NODE_ENV=='development') {
  Object.assign(config.production, config.development)
}
Vue.prototype.$config = config.production

function _version() {
  const num = require("./../../../package.json").version
  let prefix = ""
  if (config.production.ISDEV) {
    prefix = "dev"
  }
  return {num: num, prefix: prefix}
}
Vue.prototype.$version = _version()

Vue.use(Additions)

new Vue({
  render: h => h(Main),

  data() { return {
    check: true,
    start: true, // первая загрузка
    transfer: false, // true, если выполняется запрос api
    barShadeShow: false,
    barComShadeShow: false,
    auth: false,

    loading: true,
    loadingLog: '',
    loadingModal: false,
    loadingModalLog: '',
    
    alert: false,
    confirm: false,
    confirmFunc: null,
    modal: false,
    modalData: null,
    isMobile: /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(navigator.userAgent),

    storage: {
      version: 14,
      currentComponent: null,
      cappers: {
        bet: {
          date: this.$datetime(),
          coeff: null,
          status: 0,
          sport: 'Футбол',
          summ: null
        },
        filter: {month: null, day: null, from: null, to: null}
      },
      live: {
        favorites: [],
        filter: null
      },
      odds: {
        filter: {
          filters: [],
          favorites: [],
          dump: null
        },
        order: 'dt',
      }
    }
  }},

  computed: {
    isDesctop: function() {
      return !this.isMobile 
    },
    preload: function() {
      if (!this.storage.currentComponent || this.storage.currentComponent=='Auth') return true
      return false
    },
    mobileFunction: function() {
      if (window['mobileFunction'] !== undefined) {
        return window['mobileFunction']
      }
      else {
        return {
          pageFinished: () => {},
          updateVersion: () => {},
          vibrate: () => {},
          toastShort: () => {},
          toastLong: () => {},
          clickSound: () => {},
          tts: () => {}
        }
      }
    }
  },

  created: function() {
    this.created()
  },

  methods: {
    
    created: function() {
      let t =  this
      this.$api({loading:true}).then(() => {
        function set() {
          t.storage.currentComponent = 'Home'
        }
        function checkStorageVersion() {
          const ver = JSON.parse(localStorage.getItem('storage')).version
          if (ver == t.storage.version) return true
          return false
        }
        if (localStorage.getItem('storage')) {
          if (checkStorageVersion()) {
            if (t.check) {
              this.storage = JSON.parse(localStorage.getItem('storage'))
            }
          } else {
            localStorage.removeItem('storage')
            set()
          }
        } else {
          set()
        }
      })
    },

    updateVersion: function(response) {
      if (this.start && this.$version.num != response.data.data.ver) {
        this.mobileFunction.updateVersion()
        console.log("updateVersion")
      }
    }

	},

  watch: {
    storage: {
      handler: function () {
        const thisStorage = JSON.stringify(this.$root.storage)
        if (localStorage.getItem('storage') != thisStorage) {
          localStorage.setItem('storage', thisStorage)
        }
      },
      deep: true
    },
    loading: function(newval) {
      if (!newval && this.start) { {
          this.start = false
          this.mobileFunction.pageFinished()
      }
      }
    }
  },

  mounted() {  
    // let t =  this
    // setInterval(function() {
    //   if (!t.preload) {
    //     t.$api({params:{ping:true}, retries:0})
    //   }
    // }, 15000)

    this.$nextTick(function() {
      window.addEventListener('keyup', event => {
        if (event.keyCode === 27) { 
          this.barShadeShow = false
          this.barComShadeShow = false
          this.$modal('hide')
          this.$alert('hide')
          this.$confirm('hide')
        }
      })
    })
  }

}).$mount('#main')