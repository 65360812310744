<template>
  <div id="home">
    <img src="@/assets/logo/1024_mono.svg">
    <div class="menu">
      <a @click="$s();$com('Home')"><i class="fas fa-home"></i> Дом</a>
      <a @click="$s();$com('News')"><i class="fas fa-newspaper"></i> Новости</a>
      <a @click="$s();$com('Pic')"><i class="fas fa-image"></i> Pic</a>
      <a @click="$s();$com('Live')"><i class="fas fa-futbol"></i> Live</a>
      <a @click="$s();$com('Odds')"><i class="fas fa-money-check-alt"></i> Прогрузы</a>
      <a @click="$s();$com('Cappers')"><i class="fas fa-chart-area"></i> Статистика</a>
      <a @click="$s();$com('Tuning')"><i class="fas fa-cog"></i> Настройки</a>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document.title = 'Project management'
  }
}
</script>

<style scoped lang="scss">
  #home {
    position: relative;
    height:100%;
    img {
      position: fixed;
      width:200px;
      height:200px;
      top:50%;
      left:50%;
      margin-top:-82px;
      margin-left:-100px;
      z-index:-1000;
      opacity: 0.3;
    }
  }
  .menu {
    // text-align:center;
    padding:30px 20px 20px 20px;
    a {
      display: block;
      font-size:1.625rem;
      cursor:pointer;
      margin-bottom: 30px;
      font-weight: 500;
      color: $color-061;
    }
    i {
      font-size:1.25rem;
      position: relative;
      width:30px;
    }
  }
  
  .mobile {
    .menu {
      i {
      font-size:1.125rem;
    }
    }
  }

  @media (max-width: 500px) {
    #home {
      img  {
        width:160px;
        height:160px;
        margin-top:-62px;
        margin-left:-80px;
      }
    }
  }
</style>